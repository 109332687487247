<template>
  <div>
    <NuxtLayout
      name="teasers"
      v-bind="{ loading }"
      :no-results="!teasers.length"
    >
      <ScratchcardTeaser
        v-for="(teaser, index) in teasers"
        :key="index"
        v-bind="teaser"
        :fetchpriority="index < priorityFetchAmount ? 'high' : 'low'"
        @click="handleTeaserClick(teaser)"
      />

      <template #no-results>
        <NoResults
          :headline="$t('playnow.tab.scratchcards.noresults')"
          illustration="sold-out"
          variant="outlined"
        />
      </template>
    </NuxtLayout>
  </div>
</template>

<script lang="ts" setup>
import {
  KnownProductType,
  type ScratchcardTeaserPayload,
} from '~/@types/generated/backend/graphql-schema-types'

type PlayNowScratchcardsProps = {
  pollInterval?: number
}

const props = withDefaults(defineProps<PlayNowScratchcardsProps>(), {
  pollInterval: 3_000_000,
})

const licensedTerritory = useLicensedTerritory({ raw: true })

const { loading, result } = useDefaultTeasersQuery(
  { licensedTerritory, productType: KnownProductType.Scratchcard },
  {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    pollInterval: props.pollInterval,
  },
)

const teasers = computed(
  () =>
    (result.value?.defaultTeasers.teasers ?? []) as ScratchcardTeaserPayload[],
)

const { isDesktop } = useDevice()
const priorityFetchAmount = computed(() => (isDesktop ? 6 : 3))

const TRACKING_LIST_NAME = 'Play Now Scratchcard Teasers'
const TRACKING_LIST_ID = 'play_now_scratchcard_teasers'

const { createTeaserListItems } = useTrackingData()
const { pushSelectListItemEvent, pushViewListEvent } = useTracking()
const { logTrackingError } = useTrackingErrorLog()

const handleTeaserClick = (teaser: ScratchcardTeaserPayload) => {
  try {
    pushSelectListItemEvent({
      items: createTeaserListItems([teaser]),
      list_id: TRACKING_LIST_ID,
      list_name: TRACKING_LIST_NAME,
    })
  } catch (err) {
    logTrackingError('select_list_item', err)
  }
}

let unwatchTeasers: (() => void) | null = null
unwatchTeasers = watchImmediate(teasers, (list) => {
  if (!list || list.length === 0) return

  try {
    pushViewListEvent({
      items: createTeaserListItems(list),
      list_id: TRACKING_LIST_ID,
      list_name: TRACKING_LIST_NAME,
    })

    if (unwatchTeasers) unwatchTeasers()
  } catch (err) {
    logTrackingError('view_list', err)
  }
})
</script>

<style lang="scss" scoped>
.no-results {
  min-width: calc(100vw - #{spacing('md')});
  border: 0.0625rem solid color('secondary600');

  p {
    white-space: pre-wrap;
  }

  @include media-desktop {
    min-width: 47rem;
  }
}
</style>
